.maincontrolinner{
    margin-top: 78px;
    height: calc(95vh - 78px);
    overflow: auto;
}

.footer{
    z-index:4;
    position:absolute;
    bottom:0;
    width:100%;
    height: 6vh;
}

.contentinside{
    background-color: #2B2D2F;
    color:white;
    padding: 15px 30px 10px 30px;
    font-size:10px;
    text-align: center;
    margin-left:240px;
}